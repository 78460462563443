import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import { Modal } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        youtubeID
        date(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`

const VideoProduction = ({ data }) => {
  const [show, setShow] = useState(false)
  const post = data.markdownRemark

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <section id="slider" className="hero p-0 odd featured mt-5 mt-lg-0">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
          <div className="swiper-wrapper">
            <BackgroundImage
              Tag="section"
              className="swiper-slide slide-center post-hero"
              fluid={post.frontmatter.image.childImageSharp.fluid}
            >
              <div className="slide-content row text-center">
                <div className="col-12 mx-auto inner">
                  <nav
                    data-aos="zoom-out-up"
                    data-aos-delay="800"
                    aria-label="breadcrumb"
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/video-production">Video Production</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {post.frontmatter.title}
                      </li>
                    </ol>
                  </nav>
                  <h1 className="mb-0 title effect-static-text">
                    {post.frontmatter.title}
                  </h1>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </div>
      </section>
      <section id="video" className="section-1 highlights image-center">
        <div className="container smaller">
          <div className="row text-center">
            <div className="col-12 gallery">
              <a
                onClick={handleShow}
                href="#!"
                data-toggle="modal"
                data-target="#videoModal"
                className="square-image d-flex justify-content-center align-items-center"
              >
                <i className="icon bigger fas fa-play clone"></i>
                <i className="icon bigger fas fa-play"></i>
                <Img
                  className="fit-image"
                  fluid={post.frontmatter.image.childImageSharp.fluid}
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-80w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title="videoModal"
            class="embed-responsive-item"
            src={`https://www.youtube.com/embed/${post.frontmatter.youtubeID}?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </Layout>
  )
}

export default VideoProduction
